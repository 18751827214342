/**
 * Created by ling on 22-6-23.
 */
import { fetch, post, del } from 'api/base/axios'

const URL = {
  checkCertificateUrl: '/certificate/get_certificate/',

  getUserCertificateUrl: '/certificate/get_user_certificate/',
}


export function checkCertificate (data) {
  return post(URL.checkCertificateUrl, data)
}


export function getUserCertificate (data) {
  return post(URL.getUserCertificateUrl, data)
}

