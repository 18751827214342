<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">我的证书</h4>
      <el-row :gutter="30" v-if="certList.length > 0">
        <el-col :span="12" v-for="item in certList" :key="item.id">
          <el-card :body-style="{ padding: '40px 30px', zIndex: 1, position: 'relative' }" class="card_item_common">
            <h3 class="text_ellipsis" :title="item.certificate.name">{{ item.certificate.name }}</h3>
            <p>证书编号：{{ item.serial_no }}</p>
            <p>发证日期：{{ item.created_at }}</p>
          </el-card>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无内容哦~"></el-empty>
    </div>
  </div>
</template>

<script>
import Pagination from "components/common/Pagination"
import { getUserCertificate } from "api/certificate/detail"
import { checkIDCardNo } from 'utils/common.js'

export default {
  components: {
    Pagination
  },
  data() {
    return  {
      certList: []
    }
  },
  mounted() {
      this.getData()
  },
  methods: {
      getData(){
        getUserCertificate().then(result => {
            this.certList = result.data
        })
      }
  }
}
</script>

<style lang="scss" scoped>
.expt_list_common {
  .content {
    .info {
      > a {
        color:#666;
        &:hover {
          color:#00adef;
        }
      }
    }
  }
}
.card_item_common {
  border:none;
  border-radius:10px;
  -webkit-box-shadow: 2px 2px 8px rgba(0,0,0,0.08);
  box-shadow: 2px 2px 8px rgba(0,0,0,0.08);
  position: relative;
  overflow:hidden;
  h3 {
    margin:0;
    padding-bottom:20px;
    font-size:20px;
  }
  p {
    margin-bottom:0;
    margin-top:15px;
    font-size:12px;
    color:#666;
  }
  &:before {
    content:'';
    width:126px;
    height:129px;
    background:url("~@/assets/img/cert_bg1.png") no-repeat;
    position: absolute;
    left:-51px;
    top:-54px;
    z-index:0;
  }
  &:after {
    content:'';
    width:150px;
    height:152px;
    background:url("~@/assets/img/cert_bg2.png") no-repeat;
    position: absolute;
    right:-33px;
    bottom:-36px;
    z-index:0;
  }
}
</style>
